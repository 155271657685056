import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    return (
        allowedRoles ? 
          ( 
            auth?.roles?.find(role => allowedRoles?.includes(role)) ? <Outlet />: 
            <Navigate to="/unauthorized" state={{ from: location }} replace />
          ):
          ( auth?.user? 
            <Outlet />: 
            <Navigate to="/login" state={{ from: location }} replace />
          )
    );
}

export default RequireAuth;