import React, { useState } from 'react'
import { FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL } from '../../constants/index'
import { useNavigate } from "react-router-dom";
import { parseJwt } from "../../utils/security";
import useAuth from "../../hooks/useAuth";
import useAuthService from "../../services/AuthService";

const Login = () => {
  const navigate = useNavigate();
  const AuthService = useAuthService();
  const {auth,setAuth} = useAuth();

  const [credential, setCredential] = useState({
    userNameOrEmail: "",
    password: "",
  })
  const handleChange = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value })
  }
  const onFormSubmit = (e) => {
    e.preventDefault();
    AuthService.logIn(credential).then((response) => {
      const accessToken = response.data.token;
      console.log(parseJwt(accessToken));
      const firstName = parseJwt(accessToken).firstName;
      const user = {firstName};
      console.log("logIn");
      setAuth({user,accessToken});
      navigate("/myGames");
    }).catch((err) => {
      console.log(err);
    });
  }
  const signUp = (e) => {
    e.preventDefault();
    navigate("/signUp");
  }
  if (auth?.user && auth?.accessToken) {
    return <div>
      <p>Logged in as "{auth?.user.firstName}"!</p>
    </div>
  }
  const handleOAuthLink = (e, link) => {
    e.preventDefault();
    window.location.href=link;
  }
  return (
    <form className="flex flex-col max-w-2xl mx-auto shadow border-b" onSubmit={onFormSubmit}>
      <div className="px-8 py-8">
        <div className="font-thin text-2xl tracking-wider">
          <h1>Login Here</h1>
        </div>
        <div className="items-center justify-center h-14 w-full my-4">
          <label className="block text-gray-600 text-sm font-normal">Name or Email</label>
          <input type="text" name="userNameOrEmail" autoFocus  placeholder="Email or User" value={credential.userNameOrEmail} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
        </div>
        <div className="items-center justify-center h-14 w-full my-4">
          <label className="block text-gray-600 text-sm font-normal">Password</label>
          <input type="password" name="password" placeholder="password" value={credential.password} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
        </div>
        <div className="items-center justify-center h-14 w-full my-4 pt-4 space-x-4">
          <button onClick={onFormSubmit} className="rounded text-white font-semibold bg-green-400 px-6 py-2 hover:bg-green-700">Log In</button>
          <button onClick={signUp} className="rounded text-white font-semibold bg-red-400 px-6 py-2 hover:bg-red-700">Register</button>
        </div>
        <button className="loginBtn loginBtn--facebook" onClick={(e) =>handleOAuthLink(e, FACEBOOK_AUTH_URL)} >Login in with Facebook</button>
        <button className="loginBtn loginBtn--google" onClick={(e) =>handleOAuthLink(e, GOOGLE_AUTH_URL)}>Login in with Google</button>
      </div>
    </form>
  )
}

export default Login