import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useUserService from "../../services/useUserService";

const UpdateUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const UserService = useUserService();
    const [user, setUser] = useState({
        id: "",
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });
    useEffect(() => {
      UserService.findUserById(id)
            .then((response) => {
                setUser(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [id,UserService]);

    const updateUser = (e) => {
        e.preventDefault();
        UserService.updateUser(id, user)
            .then(navigate("/userList"))
            .catch((error) => { console.log(error); });
    }
    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }
    return (
        <div className="flex max-w-2xl mx-auto shadow border-b">
            <div className="px-8 py-8">
                <div className="font-thin text-2xl tracking-wider">
                    <h1>Update User</h1>
                </div>
                <div className="items-center justify-center h-14 w-full my-4">
                    <label className="block text-gray-600 text-sm font-normal">User</label>
                    <input type="text" name="userName" value={user.userName} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
                </div>
                <div className="items-center justify-center h-14 w-full my-4">
                    <label className="block text-gray-600 text-sm font-normal">Password</label>
                    <input type="text" name="password" value={user.password} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
                </div>
                <div className="items-center justify-center h-14 w-full my-4">
                    <label className="block text-gray-600 text-sm font-normal">First Name</label>
                    <input type="text" name="firstName" value={user.firstName} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
                </div>
                <div className="items-center justify-center h-14 w-full my-4">
                    <label className="block text-gray-600 text-sm font-normal">Last Name</label>
                    <input type="text" name="lastName" value={user.lastName} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
                </div>
                <div className="items-center justify-center h-14 w-full my-4">
                    <label className="block text-gray-600 text-sm font-normal">Email</label>
                    <input type="email" name="email" value={user.email} onChange={(e) => handleChange(e)} className="h-10 w-96 border mt-2 px-2 py-2"></input>
                </div>
                <div className="items-center justify-center h-14 w-full my-4 pt-4 space-x-4">
                    <button onClick={updateUser} className="rounded text-white font-semibold bg-green-400 px-6 py-2 hover:bg-green-700">Update</button>
                    <button onClick={() => { navigate("/userList") }} className="rounded text-white font-semibold bg-red-400 px-6 py-2 hover:bg-red-700">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default UpdateUser