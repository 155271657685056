import {BALLS_PER_LINE} from "./FiveBallGame"
import {GRID_SIDE_LENGTH} from "./FiveBallBoard"

export const getIndexX = (index) => {
  return index % BALLS_PER_LINE;
};
export const getOriginalPositionX = (index) => {
  return getIndexX(index) * GRID_SIDE_LENGTH + GRID_SIDE_LENGTH/2;
};
export const getOriginalPositionY = (index) => {
  return getIndexY(index) * GRID_SIDE_LENGTH + GRID_SIDE_LENGTH/2;
};

export const getIndexY = (index) => {
  return Math.floor(index/BALLS_PER_LINE);
};