import "./App.css";
import UserList from "./components/Users/UserList";
import Navbar from "./components/Navbar";
import SignUp from "./components/Users/SignUp";
import UpdateUser from "./components/Users/UpdateUser";
import { Routes, Route } from "react-router-dom"
import GameList from "./components/Games/GameList";
import FiveBallGame from "./components/Games/FiveBallGame";
import Login from "./components/Users/Login";
import AuthRedirect from "./components/auth/AuthRedirect";
import RequireAuth from "./components/auth/RequireAuth";
import MyGames from "./components/Games/MyGames";


function App() {
  return <>
    <Navbar />
    <Routes>
      <Route path="/index" element={<GameList />} />
      <Route path="/" element={<GameList />} />
      <Route element={<RequireAuth />}>
        <Route path="/userList" element={<UserList />} />
        <Route path="/myGames" element={<MyGames />}></Route>
      </Route>
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/logIn" element={<Login />} />
      <Route path="/updateUser/:id" element={<UpdateUser />} />
      <Route path="/gameList" element={<GameList />} />
      <Route path="/game/fiveBallGame">
        <Route path=":id" element={<FiveBallGame />} />
        <Route path="" element={<FiveBallGame />} />
      </Route>
      <Route path="/auth/redirect" element={<AuthRedirect />}></Route>
    </Routes>
  </>;
}

export default App;
