import { useEffect } from 'react'
import {  useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { parseJwt } from "../../utils/security";

const AuthRedirect = () => {
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const error = searchParams.get('error');
  const navigate = useNavigate();
  useEffect(() => {
    const storeToken = () => {
      if (token) {
        const firstName = parseJwt(token).firstName;
        const user = { firstName };
        console.log("AuthRedirect");
        setAuth({ user, accessToken: token });
      }else{
        console.log(error);
      }
    };
    storeToken();
    navigate("/logIn");
  },[setAuth,error,token,navigate]);
}
export default AuthRedirect