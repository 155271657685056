import { getIndexX, getIndexY,getOriginalPositionX,getOriginalPositionY } from "./FiveBallUtils";
import {GRID_SIDE_LENGTH ,MOVING_SPEED} from "./FiveBallBoard";
export default class FiveBall {
  constructor(color, index, ctx, grid) {
    this.color = color;
    this.index = index;
    this.grid = grid;
    this.position = { x: getOriginalPositionX(this.index), y: getOriginalPositionY(this.index) }
    this.ctx = ctx;
    this.selected = false;
    this.counter = -1;
  }
  bouncingDirection = -1;
  draw = () => {
    if (this.color == null) {
      this.grid.draw();
      return;
    }
    const originalY =  getOriginalPositionY(this.index);
    if (this.selected) {//selected ball bouncing effect
      this.position.y = this.position.y + (1 * this.bouncingDirection);
      if (this.position.y >= originalY + 5 || this.position.y <=originalY - 5) {
        this.bouncingDirection *= -1;
      }
    } else {
      this.position.y = originalY;
    }
    this.position.x =  getOriginalPositionX(this.index);
    this.update();
    if (this.selected) {
      requestAnimationFrame(this.draw);
    } else {
      return;
    }
  }
  setSelected = (selected) => {
    this.selected = selected;
    this.draw()
  }
  move = (moveResult, shortestPath, fiveBallGrids,callback) => {
    if (this.position.x === getOriginalPositionX(shortestPath[0].index) && this.position.y === getOriginalPositionY(shortestPath[0].index)) {
      const arrived = shortestPath.shift();//move to next grid
      this.position.x = arrived.indexX * GRID_SIDE_LENGTH + GRID_SIDE_LENGTH/2;
      this.position.y = arrived.indexY * GRID_SIDE_LENGTH + GRID_SIDE_LENGTH/2;
      this.index = arrived.index;
    }
    if (shortestPath.length === 0) {//finished moving ball
      callback(moveResult);
      return;
    }
    if (shortestPath[0].indexX !== getIndexX(this.index) ) {
      this.position.x += MOVING_SPEED * (shortestPath[0].indexX -  getIndexX(this.index));
    } else if (shortestPath[0].indexY !==  getIndexY(this.index)) {
      this.position.y += MOVING_SPEED * (shortestPath[0].indexY - getIndexY(this.index));
    }
    this.grid = fiveBallGrids[shortestPath[0].index];
    fiveBallGrids[this.index].draw();
    this.update();
    requestAnimationFrame(() => {
      this.move(moveResult,shortestPath, fiveBallGrids,callback);
    });
  }
  update = () => {
    this.grid.draw();
    if(this.color ===  null){
      return;
    }
    this.ctx.fillStyle = this.color.toLowerCase();
    this.ctx.beginPath();
    this.ctx.arc(this.position.x, this.position.y, (GRID_SIDE_LENGTH * 3)/8 , 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.closePath();
  }
  disappear = () =>{
    if(this.counter < 180){
      this.counter ++ ;
    }else{
      this.counter = 0;
      return;
    }
    if ( (Math.floor((this.counter % 100)/10) % 2 === 0 )){
      this.grid.draw();
    }else{
      this.update();
    }
    requestAnimationFrame(this.disappear);
  }
}

export const ANIMATION_TYPE_SELECT = "SELECTED";
export const ANIMATION_TYPE_MOVEING = "MOVEING";
export const ANIMATION_TYPE_DISAPPEARING = "DISAPPEARING";
export const ANIMATION_TYPE_APPEARING = "APPEARING";