import { createContext, useState } from "react";
import MyAxios from "../services/MyAxios";
import { parseJwt } from "../utils/security";


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;