import MyAxios from "../services/MyAxios";
import useAuth from './useAuth';

const useRefreshToken = () => {
    const {setAuth} = useAuth();
    const refresh = async () => {
        const response = await MyAxios.get('/api/v1/auth/refreshToken', {
            withCredentials: true
        }).catch (err => {
            console.log(err);
        });
        console.log("refresh token");
        setAuth(prev => {
            return { ...prev, accessToken: response.data.token }
        });
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;