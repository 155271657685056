import React from "react" //rafce
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useAuthService from "../services/AuthService";

const Navbar = () => {
    const {auth,setAuth} = useAuth();
    const navigate = useNavigate();
    const AuthService = useAuthService();
    const pages = [
        {label:'Games', path:'/gameList'}, 
        {label:'Users',path:'/userList'}, 
        {label:'My Games',path:'/myGames'}, ];
    const settings = ['Profile', 'Dashboard', 'Logout'];
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    
    const handleMenuClick = (e,page) => {
        e.preventDefault();
        navigate(page.path);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (event,setting) => {
        setAnchorElUser(null);
        if(setting === "Logout" ){
          //  AuthService.
            setAuth({});
            navigate("/gameList");
        }
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MAX-GAMES
                    </Typography>
                
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.label}
                                onClick={(e) => handleMenuClick(e, page)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>
                    {auth?.user?.firstName ? (
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={auth?.user?.firstName} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={(e) => handleCloseUserMenu(e,setting)}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> 
                    ):(
                      <>
                        <Button
                          key="Sign in"
                          onClick={(e) => handleMenuClick(e, { "path": "/login" })}
                          sx={{ my: 2, color: 'white', display: 'block' }}>
                          Sign in
                        </Button>
                        <Button key="Sign Up"
                          onClick={(e) => handleMenuClick(e, { "path": "/signUp" })}
                          sx={{ my: 2, color: 'white', display: 'block' }}>
                            Sign Up
                        </Button>
                      </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar
