import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAxios from "./MyAxios";

const UserService = { };
const useUserService = () => {
  const USER_API_URL = "/api/v1/users";
  const axiosPrivate = useAxiosPrivate();
  const saveUser = (user) => {
    return MyAxios.post(USER_API_URL, user);
  }
  const getAllUsers = () => {
    return axiosPrivate.get(USER_API_URL);
  }
  const deleteUser = (id) => {
    return axiosPrivate.delete(USER_API_URL + "/" + id);
  }
  const updateUser = (id, user) => {
    return axiosPrivate.put(USER_API_URL + "/" + id, user);
  }
  const findUserById = (id) => {
    return axiosPrivate.get(USER_API_URL + "/" + id);
  }
  UserService.saveUser = saveUser;
  UserService.deleteUser = deleteUser;
  UserService.findUserById = findUserById;
  UserService.getAllUsers = getAllUsers;
  UserService.updateUser = updateUser;
  return UserService;
}
export default useUserService;