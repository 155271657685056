
export const BACKEND_BASE_URL =  process.env.REACT_APP_ONLINEGAMES_BACKEND_BASE_URL;

export const ACCESS_TOKEN = "accessToken";

export const AUTH_REDIRECT_URI = process.env.REACT_APP_ONLINEGAMES_FRONTEND_BASE_URL +"/auth/redirect"

export const GOOGLE_AUTH_URL = BACKEND_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + AUTH_REDIRECT_URI;

export const FACEBOOK_AUTH_URL = BACKEND_BASE_URL + "/oauth2/authorize/facebook?redirect_uri=" + AUTH_REDIRECT_URI;
