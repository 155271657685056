import { Logout } from "@mui/icons-material";
import MyAxios from "./MyAxios";

const AUTH_LOGIN_URL = "/api/v1/auth/login";
const AUTH_LOGOUT_URL = "/api/v1/auth/logout";
const SIGN_UP_URL = "/api/v1/auth/signUp";
const AuthService = {};
const useAuthService = () => {
  const logIn = (userInfo) => {
    return MyAxios.post(AUTH_LOGIN_URL, userInfo, {
      crossDomain: true,
      withCredentials: true
    });
  }
  const logOut = (userInfo) => {
    return MyAxios.get(AUTH_LOGOUT_URL, userInfo, {
      crossDomain: true,
      withCredentials: true
    });
  }
  const signUp = (userInfo) => {
    return MyAxios.post(SIGN_UP_URL, userInfo, {
      crossDomain: true
    });
  }
  AuthService.logIn = logIn;
  AuthService.logOut = Logout;
  AuthService.signUp =signUp;
  return AuthService;
}

export default useAuthService;