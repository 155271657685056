import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
const GameCard = ({gameInfo,navigate}) => {
    const playGame = (e) => {
        e.preventDefault();
        console.log(e);
        navigate(`/game/${gameInfo.name}`);
    }
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={gameInfo.imageUrl}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {gameInfo.name}
                </Typography>
                <Tooltip title = {gameInfo.description}>
                <Typography variant="body2" style={{
                    "overflow": "hidden",  
                    "wordWrap": "break-word",
                    "textOverflow":"ellipsis",
                    "display": "-webkit-box",
                    "WebkitLineClamp": "3",
                    "WebkitBoxOrient": "vertical",
                    }} color="text.secondary">
                    {gameInfo.description}
                </Typography>
                </Tooltip>
            </CardContent>
            <CardActions>
                <Button onClick={playGame} size="small" disabled={!gameInfo.enabled }>Play</Button>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    )
}

export default GameCard