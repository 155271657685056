import  {getIndexX,getIndexY} from "./FiveBallUtils";
import {GRID_SIDE_LENGTH  } from "./FiveBallBoard";
export default class FiveBallGridCTXWrapper {
  constructor (index,ctx){
    this.index = index;
    this.indexX = getIndexX(index);
    this.indexY = getIndexY(index);
    this.ctx = ctx;
  }
  draw = () => {
    if((this.indexX + this.indexY) %2){
      this.ctx.fillStyle = '#000000'
    }else{
      this.ctx.fillStyle = '#212421'
    }
    this.ctx.beginPath();
    this.ctx.fillRect(this.indexX * GRID_SIDE_LENGTH,this.indexY * GRID_SIDE_LENGTH  ,  GRID_SIDE_LENGTH, GRID_SIDE_LENGTH);
    this.ctx.stroke();
    this.ctx.closePath();
  }
}
