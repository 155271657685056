import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import GameCard from './GameCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyAxios from "../../services/MyAxios";

const GameList = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState(null);
  useEffect(() => {
    MyAxios.get("/api/v1/public/games?" + Math.random()).then((response) => {
      setGames(response.data);
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} margin={2}>
        {games != null && (
          games.map((gameInfo) => (
            <Grid key={gameInfo.name} xs={12} sm={6} md={4} lg={3}>
              <GameCard gameInfo={gameInfo} navigate={navigate}></GameCard>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}

export default GameList