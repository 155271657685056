import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import UserRow from "./UserRow";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useUserService from "../../services/useUserService";

const UserList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const userService = useUserService();
  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      setLoading(true)
      userService.getAllUsers().then((response) => {
        console.log("user service call")
        setUsers(response.data);
      });
      setLoading(false);
    }
    console.log("userList use Effect");
    fetchData();
    return () => {
      controller.abort();
    }
  }, [userService]);
  const deleteUser = (e, id) => {
    e.preventDefault();
  }
  return (
    loading ?
      <Backdrop open={true}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      :
      <div className="container max-auto my-8">

        <div className="h-12">
          <button onClick={() => navigate("/signUp")} className="rounded bg-slate-600 text-white px-6 py-2 font-semibold">Add User</button>
        </div>
        <div className="flex shadow border-b">
          <table className="bg-gray-50 min-w-full">
            <thead>
              <tr>
                <th className="text-left font-medium text-gray-500 uppercase tracking-wider py-3 px-6">userName</th>
                <th className="text-left font-medium text-gray-500 uppercase tracking-wider py-3 px-6">First Name</th>
                <th className="text-left font-medium text-gray-500 uppercase tracking-wider py-3 px-6">Last Name</th>
                <th className="text-left font-medium text-gray-500 uppercase tracking-wider py-3 px-6">Email</th>
              </tr>
            </thead>
            {!loading && (
              <tbody className="bg-white">
                {users?.map((user) => (
                  <UserRow user={user} deleteUser={deleteUser} key={user.id}></UserRow>
                ))}
              </tbody>
            )}

          </table>
        </div>
      </div>
  );
}

export default UserList