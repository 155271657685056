import React, { useEffect, useRef,useMemo } from 'react';
import FiveBallGridCTXWrapper from "./FiveBallGridCTXWrapper";
import FiveBallCTXWrapper from "./FiveBallCTXWrapper";
import { ACTION_TYPE_SELECT,ACTION_TYPE_MOVING,BALLS_PER_LINE } from "./FiveBallGame";

const FiveBallBoard = ({ fiveBallGameData, clickOnBall,animationDone }) => {
  const boardCanvasRef = useRef(null);
  const nextBallsCanvasRef = useRef(null);
  const gridCTXWrappers = useMemo ( () => [],[]); 
  const ballCTXWrappers = useMemo ( () => [],[]);
  const nextBallsCTXWrappers = useMemo ( () => [],[]);
  useEffect(() => {
    const canvas = boardCanvasRef.current;
    const ctx = canvas.getContext('2d')
    for(let index =0 ;index<fiveBallGameData.balls.length ;index++) {
      if(gridCTXWrappers.length <= index){
        gridCTXWrappers.push(new FiveBallGridCTXWrapper(index, ctx));
      }
      gridCTXWrappers[index].draw();
      if(ballCTXWrappers.length <= index){
        ballCTXWrappers.push(new FiveBallCTXWrapper(fiveBallGameData.balls[index].color,index,ctx, gridCTXWrappers[index]));
      }else{
        ballCTXWrappers[index].color = fiveBallGameData.balls[index].color;
      }
      ballCTXWrappers[index].draw();
    }
    const nextBallsCanvasCTX = nextBallsCanvasRef.current.getContext('2d');
    for(let index =0 ;index<3;index++){
      if(nextBallsCTXWrappers.length <= index){
        nextBallsCTXWrappers.push(new FiveBallCTXWrapper(fiveBallGameData.nextBallColors[index],index,nextBallsCanvasCTX,new FiveBallGridCTXWrapper(index, nextBallsCanvasCTX)));
      }else{
        nextBallsCTXWrappers[index].color = fiveBallGameData.nextBallColors[index];
      }
      nextBallsCTXWrappers[index].update();
    }
  }, [fiveBallGameData,gridCTXWrappers,ballCTXWrappers,nextBallsCTXWrappers])
 
  const boardOnClick = (event) => {
    const ballClicked = getClickedBall(event.clientX - event.target.getBoundingClientRect().left, event.clientY - event.target.getBoundingClientRect().top);
    console.log(ballClicked.index);
    const result = clickOnBall(ballClicked.index);
    if(result == null){
      animationDone(null);
      return;
    }
    if(result.ACTION === ACTION_TYPE_SELECT){
      if(result.UNSELECTED !== result.SELECTED){
        if(result.UNSELECTED !== null && result.UNSELECTED !== undefined){
          const previousSelectedBall = ballCTXWrappers[result.UNSELECTED.index];
          previousSelectedBall.setSelected(false);
        }
        const fiveBall = ballCTXWrappers[result.SELECTED.index];
        fiveBall.setSelected(true);
      }
      animationDone(null);
      return ;
    }
    if(result.ACTION === ACTION_TYPE_MOVING){
      const canvas = boardCanvasRef.current;
      const ctx = canvas.getContext('2d');
      const ballCTXWrapper = ballCTXWrappers[result.FROM.index];
      ballCTXWrappers[result.FROM.index] = new FiveBallCTXWrapper(null,result.FROM.index,ctx, gridCTXWrappers[result.FROM.index]);
      ballCTXWrappers[result.FROM.index].setSelected(false);
      ballCTXWrappers[result.FROM.index].draw();
      ballCTXWrapper.setSelected(false);
      ballCTXWrapper.move(result.RESPONSE, result.SHORTEST_PATH, gridCTXWrappers,drawDisapperaBalls);
      ballCTXWrappers[result.TO.index] = ballCTXWrapper;
    }
  }
  const drawDisapperaBalls = (moveResultPromise) => {
    console.log(moveResultPromise);
    moveResultPromise.then((moveResult) => {
      console.log(moveResult.data);
      drawDisapperaBallsWithResult(moveResult.data);
    });
  }
  const drawDisapperaBallsWithResult = (moveResult) => {
    if(moveResult.disappearBalls != null && moveResult.disappearBalls.length > 0){
      if(moveResult.disappearBallsConter === undefined) {
        moveResult.disappearBallsConter = 0;
      }
      moveResult.disappearBallsConter ++;
      for(let fiveBallDisappear of  moveResult.disappearBalls){
        const fiveBallCTXWarpper = ballCTXWrappers[fiveBallDisappear.index];
        fiveBallCTXWarpper.color = fiveBallDisappear.color;
        if ((Math.floor((moveResult.disappearBallsConter % 100)/10) % 2 === 0 )){
          fiveBallCTXWarpper.grid.draw();//no ball to flash
        }else{
          fiveBallCTXWarpper.update();
        }
      }
      if(moveResult.disappearBallsConter >=30){
        animationDone(moveResult.fiveBallData);
        return;
      }else{
        requestAnimationFrame(() => {
          drawDisapperaBallsWithResult(moveResult);
      })
      }
    }else{
      animationDone(moveResult.fiveBallData);
    }
  }

  const getClickedBall = (x, y) => {
    const indexX = Math.floor(x / GRID_SIDE_LENGTH);
    const indexY = Math.floor(y / GRID_SIDE_LENGTH);
    return fiveBallGameData.balls[indexX + indexY * BALLS_PER_LINE];
  }
  return (
    <div className="flex gap-5">
      <canvas ref={boardCanvasRef} width={GRID_SIDE_LENGTH * BALLS_PER_LINE + "px"} height={GRID_SIDE_LENGTH * BALLS_PER_LINE + "px"} onClick={boardOnClick}></canvas>
      <div>
        <canvas ref={nextBallsCanvasRef}  width={GRID_SIDE_LENGTH * 3 + "px"} height={GRID_SIDE_LENGTH  + "px"}></canvas>
        <p>score:{fiveBallGameData.score}</p>
      </div>
    </div>
    
  );
};
export default FiveBallBoard;

export const GRID_SIDE_LENGTH = 40;
export let MOVING_SPEED = 0.25 * GRID_SIDE_LENGTH;