import React from "react"
import { useNavigate } from 'react-router-dom'

const UserRow = ({ user, deleteUser }) => {
    const navigate = useNavigate();
    const updateUser = (e, id) => {
        e.preventDefault();
        navigate(`/updateUser/${id}`);
    }
    return (
        <tr key={user.id}>
            <td className="text-left px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{user.userName}</div>
            </td>
            <td className="text-left px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{user.firstName}</div>
            </td>
            <td className="text-left px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{user.lastName}</div>
            </td>
            <td className="text-left px-6 py-4 whitespace-nowrap font-medium text-sm">
                <div className=" space-x-4">
                    <button onClick={(e, id) => { updateUser(e, user.id) }} className="text-indigo-600 hover:text-indigo-800 hover:cursor-pointer">
                        Edit
                    </button>
                    <button onClick={(e, id) => { deleteUser(e, user.id) }} className="text-indigo-600 hover:text-indigo-800 hover:cursor-pointer">
                        Delete
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default UserRow