import React, { useCallback } from 'react'
import MyAxios from "../../services/MyAxios";
import FiveBallBoard from "./FiveBallBoard";
import { useEffect, useState } from 'react';
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useParams, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const FiveBallGame = () => {
  const { id } = useParams();
  const FIVEBALL_API_URL = "/api/v1/public/fiveBall";
  const FIVEBALL_API_PRIVATE_URL = "/api/v1/fiveBall";
  const [fiveBallGameData, setFiveBallGameData] = useState(null);
  const [selectedBall, setSelectedBall] = useState(null);
  const [disableInteraction, setDisableInteraction] = useState(false);
  const { auth, setAuth } = useAuth();
  const privteAxios = useAxiosPrivate();
  const navigate = useNavigate();
  const initGame = useCallback((id) => {
    if (id === undefined) {
      MyAxios.post(FIVEBALL_API_URL).then((response) => {
        setFiveBallGameData(response.data);
        navigate('/game/fiveBallGame/' + response.data.uuid, { replace: true });
      }).catch((error) => {
        console.log(error);
      });
    } else {
      MyAxios.get(FIVEBALL_API_URL + "/" + id).then((response) => {
        setFiveBallGameData(response.data);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, []);
  const saveGame = () => {
    if (auth?.user?.firstName) {
      setDisableInteraction(true);
      privteAxios.put(FIVEBALL_API_PRIVATE_URL + "/" + fiveBallGameData.uuid, fiveBallGameData).then((response) => {
        console.log("game saved successfully:", response);
        setDisableInteraction(false);
      }).catch(error => {
        console.log(error);
        setDisableInteraction(false);
      })
    } else {
      console.log("not logged in user.")
    }
  }
  useEffect(() => {
    document.title = "Match 5 balls";
    initGame(id);
  }, [initGame])
  const moveBall = (from, to) => {
    const moveData = { "fromX": from.indexX, "fromY": from.indexY, "toX": to.indexX, "toY": to.indexY };
    return MyAxios.put(FIVEBALL_API_URL + "/move/" + fiveBallGameData.uuid, moveData);
  }
  const clickOnBall = (index) => {
    const ballClicked = fiveBallGameData.balls[index];
    if (disableInteraction) {
      return { "ACTION": ACTION_TYPE_FREEZING };
    }
    setDisableInteraction(true);//always disable interaction when ball is clicked and processing is not finished

    if (ballClicked.color == null && selectedBall == null) {//console.log("Clicked on empty grid without ball selected, nothing should happen");
      return null;
    }
    if (ballClicked.color == null && selectedBall != null) {//try to move selected ball
      const shortestPath = findPath(selectedBall, ballClicked);
      if (shortestPath === null) {
        console.log("Not allowed to move the selected ball: no path available,selectedBall:", selectedBall, "ballclicked:", ballClicked);
        return null;
      } else {
        const moveResultPromise = moveBall(selectedBall, ballClicked);
        setSelectedBall(null);
        return { "ACTION": ACTION_TYPE_MOVING, "RESPONSE": moveResultPromise, "FROM": selectedBall, "TO": ballClicked, "SHORTEST_PATH": shortestPath };
      }
    }
    if (ballClicked.color != null) {//re-select another ball.
      const previousSelectedBall = selectedBall;
      if (ballClicked === previousSelectedBall) {
        return null;
      }
      setSelectedBall(ballClicked);
      return { "ACTION": ACTION_TYPE_SELECT, "SELECTED": ballClicked, "UNSELECTED": previousSelectedBall };
    }
    return null;
  }
  const findPath = (fromBall, toBall) => {
    const queue = [];
    const paths = new Map();
    paths.set(fromBall, { "shortestPath": [], "grid": fromBall });
    queue.push(fromBall);
    while (queue.length > 0) {
      const sourceGrid = queue.shift();
      const emptyNeighbors = getEmptyNeighbors(sourceGrid);
      for (const neighbor of emptyNeighbors) {
        if (!paths.has(neighbor)) {
          const path = { "shortestPath": [], "grid": neighbor };
          path.shortestPath.push(...paths.get(sourceGrid).shortestPath, neighbor);
          paths.set(neighbor, path);
          queue.push(neighbor);
        } else {
          const existingShortestPath = paths.get(neighbor);
          const currentSourcePath = paths.get(sourceGrid);
          if (existingShortestPath.shortestPath.length > currentSourcePath.shortestPath.length + 1) {
            existingShortestPath.shortestPath = [];
            existingShortestPath.shortestPath.push(...currentSourcePath.shortestPath, neighbor);
            queue.push(neighbor); //shorter path found, needs to re-calculate neighbours' paths later
          }
        }
      }
    }
    return paths.get(toBall) === undefined ? null : paths.get(toBall).shortestPath;
  }
  const getEmptyNeighbors = (grid) => {
    const neighbors = [];
    const balls = fiveBallGameData.balls;
    if (grid.indexX - 1 >= 0 && balls[grid.indexX - 1 + grid.indexY * 9].color == null) {
      neighbors.push(balls[grid.indexX - 1 + grid.indexY * 9]);
    }
    if (grid.indexX + 1 < 9 && balls[grid.indexX + 1 + grid.indexY * 9].color == null) {
      neighbors.push(balls[grid.indexX + 1 + grid.indexY * 9]);
    }
    if (grid.indexY - 1 >= 0 && balls[grid.indexX + (grid.indexY - 1) * 9].color == null) {
      neighbors.push(balls[grid.indexX + (grid.indexY - 1) * 9]);
    }
    if (grid.indexY + 1 < 9 && balls[grid.indexX + (grid.indexY + 1) * 9].color == null) {
      neighbors.push(balls[grid.indexX + (grid.indexY + 1) * 9]);
    }
    return neighbors;
  }
  const animationDone = (data) => {
    if (data != null) {
      setFiveBallGameData(data);
    }
    setDisableInteraction(false);
  }
  const saveButtonColor = auth?.user?.firstName ? "text-blue-600" : "text-gray-400"
  const saveButtonTooltip = auth?.user?.firstName ? "Save your game progress to play later." : "Login to save game progress and play later.";
  return (
    <div>
      {fiveBallGameData != null &&
        <section>
          <FiveBallBoard fiveBallGameData={fiveBallGameData} setFiveBallGameData={setFiveBallGameData} clickOnBall={clickOnBall} animationDone={animationDone}></FiveBallBoard>
        </section>
      }
      <pre>Score:{fiveBallGameData == null ? 0 : fiveBallGameData.score}</pre>
      <button className="text-blue-600 pr-[20px]" onClick={() => initGame()}>New Game</button>
      <Tooltip title={saveButtonTooltip}>
        <button className={saveButtonColor} onClick={() => saveGame()}
        >Save Game</button>
      </Tooltip>


    </div>
  )
}

export default FiveBallGame
export const BALLS_PER_LINE = 9;
export const ACTION_TYPE_SELECT = "BALL_SELECTED";
export const ACTION_TYPE_MOVING = "BALL_MOVING";
export const ACTION_TYPE_ERROR = "RESPONSE_ERROR";
export const ACTION_TYPE_FREEZING = "FREEZING";