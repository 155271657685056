import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState }from 'react'
import { useNavigate } from "react-router-dom";
import useGameSaveService from "../../services/useGameSaveService";

const MyGames = () => {
  useEffect(() => {
    document.title = "My Game Saves";
  }, []);
  const navigate = useNavigate();
  const GameSaveService = useGameSaveService();
  const [gameSaves, setGameSaves] = useState(null);
  useEffect(() => {
    GameSaveService.getMyGameSaves().then((response) => {
      setGameSaves(response.data);
    });
  }, [GameSaveService]);
  const continueGame = (e,gameSave) => {
    e.preventDefault();
    navigate(`/game/${gameSave.gameName}/${gameSave.gameId}`);
 }
  return (
      <TableContainer sx={{ minWidth: 650 }}  component={Paper}>
        <Table>
          <TableHead>
            <TableRow key="header">
              <TableCell>id</TableCell>
              <TableCell>Game Name</TableCell>
              <TableCell>Save Time</TableCell>
              <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
          {gameSaves !=null && gameSaves.length > 0 &&
            (gameSaves.map((row) =>(
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell  component="th" scope="row">{row.id}</TableCell>
                <TableCell >{row.gameLabel}</TableCell>
                <TableCell >{row.saveDate}</TableCell>
                <TableCell >
                  <button className={"pr-[20px] text-blue-600"} onClick = {(e) => continueGame(e, row)}>Continue</button> 
                  <button className={ "text-blue-600"}>delete</button>
                </TableCell>
              </TableRow>
            )))
            }
          </TableBody>
        </Table>
      </TableContainer>
  );
}

export default MyGames