import useAxiosPrivate from "../hooks/useAxiosPrivate";

const GameSaveService = { };
const useGameSaveService = () => {
  const GAMESAVE_API_URL = "/api/v1/gameSaves";
  const axiosPrivate = useAxiosPrivate();
  const getMyGameSaves = () => {
    return axiosPrivate.get(GAMESAVE_API_URL);
  }
  GameSaveService.getMyGameSaves = getMyGameSaves;
  return GameSaveService;
}
export default useGameSaveService;